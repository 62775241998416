
<script setup>
import HeaderNav from '@/components/HeaderNav.vue'
import CommonFooter from '@/components/CommonFooter.vue'

import MonthTag from '@/components/MonthTag.vue'
import HeaderCalendar from '@/components/HeaderCalendar.vue'
import { ref, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import useDate from '@/composables/useDate.js'
import axios from "axios"

import BodyCalendar from '@/components/BodyCalendar.vue'
import PenddingCalendar from '@/components/PenddingCalendar.vue'
import FooterCalendar from '@/components/FooterCalendar.vue'
import changeModal from '@/components/changeModal.vue'
import pendingModal from '@/components/pendingModal.vue'
import LoadingView from '@/components/LoadingView.vue'
// import useGetToken from '@/composables/useGetToken.js'
// useGetToken()

const store = useStore()
const today = useDate().start
const days = ref(today)
const targetRow = ref()
const is_loading = ref(false)
const editType = ref('race')



const sendData = reactive({
  startId: 0,
  endId: 0,
  rowData:{}
})



const resetSendData = () => {
   sendData.startId = 0
  sendData.endId= 0
  sendData.rowData={}
}
const activeMonths = reactive([])
const currentUpdated = ref('')
const isModal = ref(false)
const thisMonthData = reactive([
  {
    id:0,
    date:'1970-01-01',
    title:'',
    row_id:'0',
    category_id:'0',
    place_id:'0',
    cols:'1',
    is_start:'0',
    is_active:'0',
    is_visible:'0',
    is_final:'0'
    }
])
const thisRaceData = (row) => {
  return thisMonthData.filter(e => Number(e.row_id) === Number(row))
}
const raceNum = () => {
   return thisMonthData.filter(e => e.is_active === 1)
}
const setActiveMonth = async () => {
    const url = store.state.url + 'init.php'
    const formData = new FormData()
  formData.append("init", "active")
    await axios.post(url, formData)
        .then(response => {
          activeMonths.length = 0
            response.data.forEach(element => {
              if (today <= element) {
                     activeMonths.push(element)
            }
            })
          currentUpdated.value = activeMonths.find(e=>e.date === days.value).updated_at
    })
}
setActiveMonth()

const setMonthCalendar = async (days) => {
  thisMonthData.length =  0
  const formData = new FormData()
  formData.append("init", "calendar")
  formData.append("date", days)
 const url = store.state.url + "race.php"
  await axios.post(url, formData)
    .then((response) => {
      response.data.forEach(e => {
        thisMonthData.push(e)
      })
    })
     .catch(function (error) {
       console.log('set',error)
      })
}
setMonthCalendar(today)


const selectDays = (event) => {
      targetRow.value = event.rowData.id
      const end = event.cols > 1 ? event.start + event.cols - 1 : event.end
        sendData.startId = event.start
        sendData.endId = end
  sendData.rowData = event.rowData
       isModal.value = 1
}
const selectPenndingDays = (event) => {
     targetRow.value = event.rowData.id
        sendData.startId = event.content.id
        sendData.endId = event.content.id
  sendData.rowData = event.rowData
   isModal.value = 2
}

watch(days, (val) => {
    setMonthCalendar(val)
    setActiveMonth()
    raceNum()
})

const changeRaceSchedule = async (content) => {
    const formData = new FormData()
      formData.append("csrf", store.state.token)
      formData.append("init", "edit")
      formData.append("year", content.year)
      formData.append("startId", content.startId)
      formData.append("endId", content.endId)
      formData.append("place_id", content.place)
      formData.append("category_id", content.category)
      formData.append("grade_id", content.grade)
      formData.append("is_final", content.is_final)
      formData.append("title", content.title)
      formData.append("dstartId", content.sorceStartId)
  formData.append("dendId", content.sorceEndId)
  const url = store.state.url + "race.php"
 await axios.post(url, formData)
    .then(() => {
        resetSendData()
         setMonthCalendar(days.value)
          setActiveMonth()
          raceNum()
          isModal.value = false
    })
     .catch(function (error) {
       console.log(error)
          resetSendData()
          isModal.value = false
          alert('通信エラー。\n再度お試しください。')
      })
}
const addPendingRaceSchedule = async (content) => {
    const formData = new FormData()
      formData.append("csrf", store.state.token)
      formData.append("init", "addpendingrace")
      formData.append("year", content.year)
      formData.append("id", content.id)
      formData.append("place_id", content.place)
      formData.append("category_id", content.category)

  const url = store.state.url + "race.php"

 await axios.post(url, formData)
    .then(() => {
        resetSendData()
         setMonthCalendar(days.value)
          setActiveMonth()
          raceNum()
          isModal.value = false
    })
     .catch(function (error) {
       console.log(error)
          resetSendData()
          isModal.value = false
          alert('通信エラー。\n再度お試しください。')
      })
}

const resetRaceSchedule = async (content) => {
   const formData = new FormData()
      formData.append("csrf", store.state.token)
      formData.append("init", "reset")
      formData.append("year", content.year)
      formData.append("startId", content.sorceStartId)
      formData.append("endId", content.sorceEndId)
    const url = store.state.url + "race.php"
      await axios.post(url, formData)
        .then(() => {
         resetSendData()
         setMonthCalendar(days.value)
          setActiveMonth()
          raceNum()
          isModal.value = false
        })
        .catch(function (error) {
          console.log(error)
          resetSendData()
          isModal.value = false
          alert('通信エラー。\n再度お試しください。')
        })
}
const cancel = () => {
  resetSendData()
  isModal.value=false
}
const resetPendingSchedule = async (content) => {
   const formData = new FormData()
      formData.append("csrf", store.state.token)
      formData.append("init", "resetPending")
      formData.append("year", content.year)
  formData.append("id", content.id)
    const url = store.state.url + "race.php"
      await axios.post(url, formData)
        .then(() => {
         resetSendData()
         setMonthCalendar(days.value)
          setActiveMonth()
          raceNum()
          isModal.value = false
        })
        .catch(function (error) {
          console.log(error)
          resetSendData()
          isModal.value = false
          alert('通信エラー。\n再度お試しください。')
        })
}

const newMonth = async (date) => {
    is_loading.value = true
    const formData = new FormData()
      formData.append("csrf", store.state.token)
      formData.append("date", date)
      formData.append("type", "create_calendar")
      const url = store.state.url + "calendar.php"
        await axios.post(url, formData)
          .then(() => {
            days.value = date
            is_loading.value = false
        })
        .catch(function (error) {
          console.log(error)
          alert('通信エラー。\n再度お試しください。')
           is_loading.value = false
        })
}
const changeEditType = (type) => {
  editType.value = type
}

const todayRaceReset = async ()=>{
       is_loading.value = true
  const formData = new FormData()
      formData.append("csrf", store.state.token)
      formData.append("type", "resettoday")
      const url = store.state.url + "today_race.php"
      console.log(url)
      await axios.post(url, formData)
          .then(() => {
            is_loading.value = false
            alert('再登録完了しました')
           
        })
        .catch(function (error) {
          console.log(error)
          alert('通信エラー。\n再度お試しください。')
           is_loading.value = false
        })
   
}


</script>
<template>
   <div class="flex justify-start flex-col h-screen pendding-back">
    <template v-if="is_loading">
        <LoadingView/>
    </template>
    <HeaderNav />
    <main>
      <div class="text-center py-10 h1-title">
      <h1 class="text-xl ">レースカレンダー編集
        <span class="block">({{ useDate(days).year }}年{{ useDate(days).month }}月)</span>
      </h1>
        <p v-show="editType=='pendding'" class="pennding-mode">中止モード</p>
      <MonthTag @get-monthdata="days = $event" :activeMonths="activeMonths" :days="days" :updated_at="currentUpdated" @new-month="newMonth($event)" :type="editType" @edit-type="changeEditType" @reset-todayrace="todayRaceReset"/>
    </div>
    <div class="race">
      <table class="race-table">
        <HeaderCalendar :days="days" />
        <tbody v-if="editType=='race'">
          <template v-for="row in store.state.rows" :key="row.id">
            <BodyCalendar :row="row" :contents="thisRaceData(row.id)" @select-days="selectDays($event)"
            :modetype="editType"
            />
          </template>
        </tbody>
        <tbody v-if="editType=='pendding'">
          <template v-for="row in store.state.rows" :key="row.id">
            <PenddingCalendar :row="row" :contents="thisRaceData(row.id)" @select-days="selectPenndingDays($event)"
            :modetype="editType"
            />
          </template>
        </tbody>
         <FooterCalendar :days="days" :races="raceNum()" />
      </table>
    </div>
    <div class="type-tag">
        <ul>
          <!-- <li>
            <div class="special type-tag-color"></div><span>スペシャル</span>
          </li> -->
          <li><div class="sg type-tag-color"></div><span>SG</span></li>
          <li><div class="pg1 type-tag-color"></div><span>PGⅠ</span></li>
          <li><div class="g1 type-tag-color"></div><span>GⅠ</span></li>
          <li><div class="g2 type-tag-color"></div><span>GⅡ</span></li>
          <li><div class="g3c type-tag-color"></div><span>GⅢ</span></li>
          <li><div class="g3m type-tag-color"></div><span>GⅢ マスターズ</span></li>
          <li><div class="g3l type-tag-color"></div><span>GⅢ オールレディース</span></li>
          <li><div class="rookie type-tag-color"></div><span>ルーキー</span></li>
          <li><div class="venus type-tag-color"></div><span>ヴィーナス</span></li>
          <li><div class="mix type-tag-color"></div><span>男女W･ほぼオール女子</span></li>
          <li><div class="gen type-tag-color"></div><span>一般</span></li>
          <!-- <li><div class="pending type-tag-color"></div><span>中止</span></li> -->
          <li><span>×：決勝戦なし</span></li>
        </ul>
    </div>

    <div v-if="isModal==1">
      <changeModal :source="sendData" :contents="thisRaceData(targetRow)" @cancel-action="cancel()"
        @change-action="changeRaceSchedule($event)" @reset-action="resetRaceSchedule($event)"  />
    </div>
    <div v-if="isModal==2">
       <pendingModal :source="sendData" :contents="thisRaceData(targetRow)" @cancel-action="cancel()"
          @change-action="addPendingRaceSchedule($event)" @reset-action="resetPendingSchedule($event)"  />
    </div>


    </main>

 <CommonFooter/>
  </div>
</template>


<style lang="scss">
.race tbody tr:nth-of-type(even) {

  th,
  td {
    background: rgba(180, 180, 180, 0.1);
  }
}
.type-tag li{
  margin-bottom: .5rem;
}

.h1-title {
  width: calc(100% - 80px);
  min-width: 1080px;
  margin: 0 auto;
  position: relative;

  &-btn {
    position: absolute;
    bottom: .5rem;
    right: 0;

  }
}

.pennding-mode{
  background: red;
  padding: .1em 0;
  font-weight: bold;
  color: white;
  text-align: center;
  font-size: 1.2em;
}
.pending{
  background: black;
}
</style>
