<script setup>

import HeaderNav from '@/components/HeaderNav.vue'
import CommonFooter from '@/components/CommonFooter.vue'
import userEdit from '@/components/users/UserEdit.vue'
import userCreate from '@/components/users/UserCreate.vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { reactive,ref} from 'vue'
import axios from "axios"
// import useGetToken from '@/composables/useGetToken.js'
const store = useStore()
const router = useRouter()
const users = reactive([{ id: 0, name: "default", permission: 0 }])
const user = reactive({ id: 0, name: "", permission: 1 })
const modal = ref(0)

const getUsers = async () => {
    const formData = new FormData();
    formData.append("csrf", store.state.token);
    formData.append("type", 'users');
    const url = store.state.url + 'users.php'
    users.length = 0
     await axios.post(url, formData)
         .then(response => {
             response.data.forEach(e => {
                 const obj = {
                     id: Number(e.id),
                     name: e.name,
                     permission : Number(e.permission)
                 }
                users.push(obj)
            })
         })
}
// getUsers()

// useGetToken().then(() => {
//     getUsers()
// })


const destroy = async (id) => {
    if (id === 1) {
        alert(`このユーザーは削除できません`)
        return false
    }
    if (confirm(`本当に削除してよろしいでしょうか？`)) {
         const formData = new FormData()
         formData.append("csrf", store.state.token)
         formData.append("type", 'distroy')
        formData.append("id", id)
        const url = store.state.url + 'users.php'
         await axios.post(url, formData)
        .then(response => {
            if (!response.data) {
                alert(`削除に失敗しました。`)
                return false
            } else {
                getUsers()
            }
        })
        .catch(e => {
            alert(`接続エラー！！`)
            console.log(e)
            return false
        })
    }
}
const edit = (data) => {
    user.id = data.id
    user.name = data.name
    user.permission = data.permission
    modal.value = 2
}
const update = async (data) => {
    
    const formData = new FormData();
     formData.append("csrf", store.state.token)
    formData.append("type", 'update')
    formData.append("id", data.id)
    formData.append("passwordchk", data.pwdChk)
     formData.append("name", data.name)
    formData.append("password", data.password)
    formData.append("permission", data.permission)
     const url = store.state.url + 'users.php'
    await axios.post(url, formData)
        .then(response => {
            if (!response.data) {
                alert(`更新に失敗しました。`)
                return false
            } else {
                if (data.pwdChk == 1 && store.state.user.id == data.id) {
                    alert(`パスワードが変更されました。\n再ログインしてください。`)
                    store.dispatch('logoutAction')
                    router.push('/login')
                }
                getUsers()
                modal.value = 0
            }
        })
        .catch(e => {
            alert(`接続エラー！！`)
            console.log(e)
            return false
        })
}
const create = async (data) => {
    const formData = new FormData();
    formData.append("csrf", store.state.token);
    formData.append("type", 'create');
    formData.append("name", data.name);
    formData.append("password", data.password);
    formData.append("permission", data.permission);
    const url = store.state.url + 'users.php'
    await axios.post(url, formData)
        .then(response => {
            if (!response.data) {
                alert(`登録に失敗しました。`)
                return false
            } else {
                getUsers()
                modal.value = 0
            }
        })
        .catch(e => {
            alert(`接続エラー！！`)
            console.log(e)
            return false
        })
}

const role = (val) => {
     if (val == 10) {
            return "Admin"
        } else {
            return "Genaral"
        }
}

getUsers()

</script>
<template>
    <div class="flex justify-start flex-col h-screen">
        <HeaderNav />
        <main>
        <div class="text-center py-10 h1-title">
            <h1 class="text-xl ">ユーザー一覧</h1>
        </div>
 
           <div class="lg:w-1/3 w-full mx-auto overflow-auto">
               <button @click="modal=1" class="hover:bg-green-700   text-xs ml-auto block mb-4 bg-green-800 py-2 px-6 rounded text-white">New</button>
      <table class="table-auto w-full text-left whitespace-no-wrap">
        <thead>
          <tr>
            <th class="text-center w-10 px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tl rounded-bl">Id</th>
            <th class="text-center px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Name</th>
            <th class="text-center w-10 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Role</th>
            <th class="w-20 marker:selection:px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100"></th>
            <th class="w-20 px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100"></th>
          </tr>
        </thead>
        <tbody>
          <tr  v-for="user in users" :key="user.id">
            <td class="px-4 py-3  text-center">{{ user.id }}</td>
            <td class="px-4 py-3">{{ user.name }}</td>
            <td class="px-4 py-3 text-center">{{ role(user.permission) }}</td>
            <td class="text-center"><button @click="edit(user)" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded text-xs">Edit</button></td>
            <td class="text-center">
              <button :disabled="user.id==1" @click="destroy(user.id)" class="disabled:bg-red-300  bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded text-xs">Delete</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <template v-if="modal === 2">
        <userEdit :user="user" :users="users" @cancel-event="modal=0" @update-user="update($event)"/>
    </template>
    <template v-if="modal === 1">
        <userCreate :users="users" @cancel-event="modal=0" @create-user="create($event)"/>
    </template>
    </main>
        <CommonFooter/>
    </div>
</template>

<style lang="scss">
.modal{
    background:rgba(0,0,0,.8);
    &-content{
        background-color: white;
    }
}


</style>